import { Grid, MenuItem, Select, TextField } from "@material-ui/core";
import { questionTypeSetup, questionTypes, vehicleDetailTypes, vehicleFieldsToMapByQType, vehicleFieldsToMapSetup } from "../formBuilderConsts";
import { FormBuilderStyles } from "../style";
import DropdownFilter from "components/DropdownFilter";
import { vehicleDetailExtendedDate, vehicleDetailExtendedMulti, vehicleDetailExtendedValues } from "features/vehicles/VehicleDetailExtended/VehicleDetailExtendedTypes";
import { orderBy } from "lodash";

const FormQuestion = ({ form, question, onChange }) => {

    const QuestionComponent = questionTypeSetup[question?.questionType]?.component;

    const couldMapToField = true;//question.questionType === questionTypes.Freetext || question.questionType === questionTypes.Dropdown;

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item container justifyContent="space-between" spacing={1}>
                <Grid item xs={couldMapToField ? 6 : 8} >
                    <TextField
                        style={{ width: '100%' }}
                        value={question?.question}
                        label=""
                        variant="outlined"
                        placeholder="Type question text here..."
                        onChange={e => {
                            onChange({ ...question, ['question']: e.target.value })
                        }}
                    />
                </Grid>
                <Grid item xs={couldMapToField ? 3 : 4}>
                    <QuestionTypeSelector
                        form={form}
                        question={question}
                        onChange={(newType) => onChange({ ...question, ['questionType']: +newType, ['mappedToFieldType']: undefined, ['mappedToField']: undefined })}
                    />
                </Grid>
                {couldMapToField &&
                    <Grid item xs={3}>
                        <MapToFieldSelector
                            form={form}
                            question={question}
                            onChange={(val) => {
                                onChange({
                                    ...question,
                                    questionType: val?.type == vehicleDetailTypes.vehicleDetailExtended && vehicleDetailExtendedValues?.[val?.field]?.length ?
                                        vehicleDetailExtendedMulti?.[val?.field] ? questionTypes.MultipleChoice : questionTypes.Dropdown : vehicleDetailExtendedDate?.[val?.field] ? questionTypes.Date : questionTypes.Freetext,
                                    ['mappedToFieldType']: val?.type,
                                    ['mappedToField']: val?.field,
                                    values: val?.type == vehicleDetailTypes.vehicleDetailExtended && vehicleDetailExtendedValues?.[val?.field]?.map((v, index) => ({ ...v, value: v.id, order: index }))
                                })
                            }}
                        />
                    </Grid>
                }
            </Grid>
            {!!QuestionComponent && <Grid item>
                <QuestionComponent
                    question={question}
                    onFieldChange={(fieldName, fieldValue) => onChange({ ...question, [fieldName]: fieldValue })}
                    presetValues={question?.mappedToFieldType == vehicleDetailTypes.vehicleDetailExtended && vehicleDetailExtendedValues?.[question?.mappedToField] ? vehicleDetailExtendedValues?.[question?.mappedToField] : null}
                />
            </Grid>}
        </Grid>
    );
}
export default FormQuestion;

const QuestionTypeSelector = ({ form, question, onChange }) => {

    const classes = FormBuilderStyles();
    const questionTypes = Object.keys(questionTypeSetup).map(key => ({
        id: key,
        name: questionTypeSetup[key]?.name
    }));

    const renderOption = (option) => {
        return <Grid container spacing={1}>
            <Grid item style={{ width: '0.7em', height: '0.7em' }}>
                {questionTypeSetup[option.id]?.icon}
            </Grid>
            <Grid item style={{ marginLeft: '1.5em' }}>
                {option?.name}
            </Grid>
        </Grid>
    }

    return (
        <>{question.id ? <div className={classes.questionType}>
            {renderOption(questionTypes.find(q => q.id == question?.questionType))}
        </div> :
            <Select native={false}
                variant='outlined'
                style={{ width: '100%', background: '#E9F5FF', border: 'none' }}
                value={question?.questionType}
                onChange={e => onChange(e.target.value)}
            >
                {questionTypes?.map((q) => (
                    <MenuItem value={q.id} id={q.id}>{renderOption(q)}</MenuItem>
                ))}
            </Select>
        }</>
    )
}

const MapToFieldSelector = ({ question, onChange }) => {

    const classes = FormBuilderStyles();
    //const fieldsList = [{ id: { type: undefined, field: undefined }, name: 'No Mapping' }].concat(vehicleFieldsToMapByQType[question.questionType]);
    const fieldsList = [{ id: { type: undefined, field: undefined }, name: 'No Mapping' }].concat(orderBy(Object.values(vehicleFieldsToMapSetup), 'name'));

    let value = fieldsList.find(q => q?.id?.type === question?.mappedToFieldType && q?.id?.field === question?.mappedToField) ?? fieldsList?.[0];

    return (
        <>
            {/* {question.id ? <div className={classes.fieldMapped}>
            {value?.name}
        </div> : */}
            <DropdownFilter
                withTypeAhead
                native={false}
                variant='outlined'
                style={{ width: '100%', background: '#efefef', border: 'none' }}
                value={value?.id}
                defaultValue={fieldsList?.[0]?.id}
                //onChange={e => onChange(e.target.value)}
                onSelect={val => {
                    onChange(val?.id)
                }}
                values={fieldsList}
            >
                {/* {fieldsList?.map((q) => (
                    <MenuItem value={q.id} id={q.id}>{q.name}</MenuItem>
                ))} */}
            </DropdownFilter>
            {/* } */}
        </>
    )
}

